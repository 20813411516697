export const DASHBOARD_BASE = "/dashboard";
export const AUTH_BASE = "/auth";

export const dashboardPaths = {
  overview: `${DASHBOARD_BASE}/overview`,
  messenger: `${DASHBOARD_BASE}/messenger`,
  payments: `${DASHBOARD_BASE}/payments`,
  settings: `${DASHBOARD_BASE}/settings`,
  notifications: `${DASHBOARD_BASE}/notifications`,
  create_new_project: `${DASHBOARD_BASE}/project/new`,
  set_project_delivery: (id) =>
    `${DASHBOARD_BASE}/project/${id}/delivery_method`,
};

export const authPaths = {
  login: `${AUTH_BASE}/login`,
  register: `${AUTH_BASE}/register`,
  verify_email: `${AUTH_BASE}/verify-email`,
  request_password_reset: `${AUTH_BASE}/forgot-password`,
  reset_password: `${AUTH_BASE}/reset-password`,
  verify_two_fa: `${AUTH_BASE}/twofa`,
};
