import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_PROJECTS({ commit, state }, payload) {
    try {
      const {
        status = "",
        page = 1,
        per_page = 10,
        search = "",
      } = payload ?? {};
      const { data } = await axiosService.get(`/user/projects`, {
        params: {
          status,
          page,
          per_page,
          search,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_SINGLE_PROJECT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/project/${payload}`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PROJECT_COUNT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/projects/status_count`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_DRAFT({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this draft?"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/user/project/${payload}/delete_draft`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_PROJECT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/user/project/create`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async OPEN_PROJECT_DISPUTE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/project/${payload.projectId}/dispute`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ACCEPT_PROJECT_SUBMISSION({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to accept this project?"
      );
      if (confirmed) {
        const { data } = await axiosService.post(
          `/user/project/${payload.projectId}/accept_submission`,
          payload
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REVIEW_AND_CLOSE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/project/${payload.projectId}/review_and_close`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async SET_DELIVERY_METHOD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/project/${payload.id}/delivery_method`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
