import axios from "axios";
import store from "@/store";
import router from "@/router";
import { BASE_URL } from "@/config";
const axiosService = axios.create({
  baseURL: BASE_URL,
});
axiosService.interceptors.request.use(
  (config) => {
    const token = store?.state?.auth?.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosService.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    console.log(err.config);
    const originalConfig = err.config;
    console.log(originalConfig.url);
    if (!originalConfig.url.includes("/login") && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = (
            await axiosService.post("/user/auth/token/refresh", {
              refreshToken: store?.state?.auth?.refreshToken,
            })
          ).data;
          const { accessToken, refreshToken } = rs.data;
          store.commit("setAccessToken", accessToken);
          store.commit("setRefToken", refreshToken);
          originalConfig.headers["Authorization"] = `Bearer ${accessToken}`;
          try {
            const response = await axios.request(originalConfig);
            return response;
          } catch (error) {
            router.push("/");
            console.log(error);
            return Promise.reject(error);
          }
        } catch (error) {
          console.log(error);
          router.push("/");
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default axiosService;
