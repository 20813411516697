<template>
  <div>
    <div class="wr-upload-area">
      <input
        type="file"
        name=""
        v-if="inputReady"
        @change="handleFileChange"
        accept="image/jpeg, image/png, application/pdf, video/mp4"
      />
      <div
        class="wr-upload-area__content d-flex align-items-center justify-content-start col-10"
      >
        <div class="wr-text__h1 mb-0 me-2 opacity-50 wr-text--dark">
          <i class="bi bi-cloud-upload-fill" v-if="progress === 0"></i>
          <div class="wr-lottie-item" v-else>
            <LottieAnimation
              ref="anim"
              :animationData="require('@/assets/lottie/file_uploading.json')"
              loop
              :speed="1"
              :autoPlay="true"
            />
          </div>
        </div>
        <div v-if="progress == 0">
          <h5 class="wr-text__body wr-text--semibold wr-text--dark mb-0">
            Drag & Drop or Browse
          </h5>
          <p class="wr-text__small wr-text--dark opacity-50 mb-0">
            File type supported: Jpg, PNG, PDF,MP4, Max Size: 20 MB
          </p>
        </div>
        <div class="wr-text--dark wr-text__body--larger text-center" v-else>
          Uploading... {{ progress }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosService from "@/services/axiosService";
import { useToast } from "vue-toastification";
export default {
  name: "FileUpload",
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      file: null,
      progress: 0,
      inputReady: true,
    };
  },
  methods: {
    resetInput() {
      this.inputReady = false;
      this.$nextTick(() => {
        this.inputReady = true;
        this.progress = 0;
      });
    },
    handleFileChange(event) {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;
      const fileSize = selectedFile.size / (1024 * 1024);
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "video/mp4",
      ];
      if (!allowedTypes.includes(selectedFile.type)) {
        this.toast.error("Invalid file. Supported types: Jpg, PNG, PDF, MP4.");
        this.resetInput();
        return;
      }
      if (fileSize > 20) {
        this.toast.error("File should not be more than 20MB");
        this.resetInput();
        return;
      }
      this.file = selectedFile;
      this.uploadFile();
    },
    async uploadFile() {
      const formData = new FormData();
      formData.append("file", this.file);
      try {
        const res = (
          await axiosService.post("/resource/upload", formData, {
            onUploadProgress: (progressEvent) => {
              this.progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
            },
          })
        ).data;
        this.$emit("fileUploaded", res.data.attachment);
      } catch (error) {
        const message =
          error?.response?.data?.message ||
          "An error occurred. Please try again.";
        this.toast.error(message);
      } finally {
        this.resetInput();
      }
    },
  },
};
</script>

<style>
.wr-upload-area {
  position: relative;
  min-height: 120px;
  width: 100%;
  background: var(--wr-grey-2);
  border: 3px dashed var(--wr-grey-4);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.wr-upload-area:hover {
  border-color: var(--wr-primary);
}
.wr-upload-area .wr-upload-area__content {
  position: relative;
  z-index: 1;
}
.wr-upload-area input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
</style>
