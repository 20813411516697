import axiosService from "@/services/axiosService";
import router from "@/router";
import { authPaths } from "@/router/path.route";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {
  user: null,
  isAuthenticated: false,
  twoFaConfirmed: false,
  rememberUser: false,
  accessToken: null,
  refreshToken: null,
};

const getters = {
  isAuthenticated: (state) => state.isAuthenticated,
  getUser: (state) => state.user,
  getTwoFaState: (state) =>
    (state.user &&
      state.user.twoFa &&
      state.user.twoFa.isEnabled &&
      !state.twoFaConfirmed) ||
    false,
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
  },
  setRefToken(state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  setIsAuthenticated(state, isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
  },
  setTwofa(state, isConfirmed) {
    state.twoFaConfirmed = isConfirmed;
  },
};

const actions = {
  async LOGIN_USER({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post("/user/auth/login", payload);
      commit("setUser", data.data.user);
      commit("setAccessToken", data.data.accessToken);
      commit("setRefToken", data.data.refreshToken);
      commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REGISTER_USER({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post("/user/auth/register", payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async VERIFY_EMAIL({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post("/user/auth/verify", payload);
      commit("setUser", data.data.user);
      commit("setAccessToken", data.data.accessToken);
      commit("setRefToken", data.data.refreshToken);
      commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async RESEND_EMAIL_OTP({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        "/user/auth/otp/resend",
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REQUEST_PWD_RESET({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        "/user/auth/password/reset_request",
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async RESET_PASSWORD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        "/user/auth/password/reset",
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REFRESH_TOKEN({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        "/user/auth/token/refresh",
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GOOGLE_AUTH({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post("/user/auth/google", payload);
      commit("setUser", data.data.user);
      commit("setAccessToken", data.data.accessToken);
      commit("setRefToken", data.data.refreshToken);
      commit("setIsAuthenticated", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_USER({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get("/user/account/profile", payload);
      commit("setUser", data.data);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async VERIFY_TWO_FA({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        "/user/account/twofa/verify",
        payload
      );
      commit("setTwofa", true);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async LOGOUT_USER({ commit, state }, payload) {
    try {
      const confirmed = await confirm("Are you sure you want to logout?");
      if (!confirmed) return;
      router.push(authPaths["login"]);
      setTimeout(() => {
        commit("setUser", null);
        commit("setAccessToken", null);
        commit("setRefToken", null);
        commit("setIsAuthenticated", false);
        commit("setTwofa", false);
      }, 2000);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
