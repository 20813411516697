import axiosService from "@/services/axiosService";
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async GET_ALL_COMMENTS({ commit, state }, payload) {
    try {
      const { projectId, page = 1, per_page = 10, search = "" } = payload ?? {};
      const { data } = await axiosService.get(`/user/comments/${projectId}`, {
        params: {
          page,
          per_page,
          search,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async NEW_COMMENT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/comments/${payload.projectId}/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
