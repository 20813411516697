<template>
  <section class="wr-app-state">
    <div class="wr-app-state__lottie">
      <LottieAnimation
        ref="anim"
        :animationData="lottiePath"
        :speed="1"
        :autoPlay="true"
      />
    </div>
    <div class="mt-4 text-center col-lg-8 mx-auto">
      <h3 class="wr-text__h4 wr-text--dark opacity-75">{{ title }}</h3>
      <p class="wr-text__body wr-text--dark opacity-50 mb-0">
        {{ description }}
      </p>
      <div class="d-flex justify-content-center mt-2" v-if="hasAction">
        <button class="wr-button" @click="handleAction">
          <span class="wr-text--primary wr-text__small">{{ actionTitle }}</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppState",
  props: {
    title: {
      type: String,
      default: "Nothing to see here",
    },
    description: {
      type: String,
      default: "You seem to have nothing to show",
    },
    actionTitle: {
      type: String,
      default: "Try Again",
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
    lottiePath: {
      type: Object,
      default: require("@/assets/lottie/success.json"),
    },
  },
  methods: {
    handleAction() {
      this.$emit("triggerAction");
    },
  },
};
</script>

<style scoped>
.wr-app-state {
  min-height: 300px;
  width: 100%;
  /* border: 2px solid var(--wr-grey-2); */
  padding: 2.5rem 2rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
}
.wr-app-state .wr-app-state__lottie {
  max-width: 120px;
}
</style>
