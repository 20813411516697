import { createStore } from "vuex";
import createPersistedState from "vuex-plugin-persistedstate";
import {
  auth,
  project,
  payment,
  resource,
  account,
  history,
  comment,
  theme,
} from "./modules";
export default createStore({
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    project,
    payment,
    resource,
    account,
    history,
    comment,
    theme,
  },
});
