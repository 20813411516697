import { dashboardPaths, DASHBOARD_BASE } from "../path.route";
console.log(dashboardPaths["overview"]);
export const DashboardRoutes = {
  path: DASHBOARD_BASE,
  name: "Dashboard",
  meta: {
    requiresAuth: true,
  },
  redirect: dashboardPaths["overview"],
  component: () => import("@/layouts/DashboardLayout.vue"),
  children: [
    {
      path: dashboardPaths["overview"],
      name: "Overview",
      component: () => import("@/views/dashboard/Overview.vue"),
    },
    {
      path: dashboardPaths["messenger"],
      name: "Messenger",
      component: () => import("@/views/dashboard/Messenger.vue"),
    },
    {
      path: dashboardPaths["payments"],
      name: "Payments",
      component: () => import("@/views/dashboard/Payments.vue"),
    },
    {
      path: dashboardPaths["settings"],
      name: "Preferences",
      component: () => import("@/views/dashboard/Settings.vue"),
    },
    {
      path: dashboardPaths["notifications"],
      name: "Notifications",
      component: () => import("@/views/dashboard/Notifications.vue"),
    },
    {
      path: dashboardPaths["create_new_project"],
      name: "NewProject",
      component: () => import("@/views/dashboard/Project/CreateNewProject.vue"),
    },
    {
      path: dashboardPaths["set_project_delivery"](":id"),
      name: "DeliveryMethod",
      component: () =>
        import("@/views/dashboard/Project/SetDeliveryMethod.vue"),
    },
  ],
};
