import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async UPDATE_PROFILE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/profile/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REQUEST_EMAIL_UPDATE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/profile/email/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async VERIFY_EMAIL_UPDATE({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/profile/email/verify`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_TWO_FA({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/account/twofa/get`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ENABLE_TWO_FA({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(`/user/account/twofa/enable`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DISABLE_TWO_FA({ commit, state }, payload) {
    try {
      const confirmed = await confirm("Are you sure you want to disable 2FA?");
      if (confirmed) {
        const { data } = await axiosService.put(`/user/account/twofa/disable`);
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PASSWORD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/password/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_NOTIFICATION({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/notifications/update`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_NOTIFICATIONS({ commit, state }, payload) {
    try {
      const { page = 1, per_page = 10 } = payload ?? {};
      const { data } = await axiosService.get(
        `/user/account/notifications/list`,
        {
          params: {
            page,
            per_page,
          },
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async MARK_NOTIFICATIONS_READ({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/notifications/list/read`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async UPDATE_PROFILE_PIC({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/photo/update`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async CREATE_DELIVERY_ADDRESS({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/account/address/new`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async SET_PREFERRED_ADDRESS({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/account/address/${payload}/set_preferred`,
        payload
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async DELETE_ADDRESS({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this address?"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/user/account/address/${payload}/delete`,
          payload
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async ADD_NEW_CARD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(`/user/card/new`, payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async REMOVE_CARD({ commit, state }, payload) {
    try {
      const confirmed = await confirm(
        "Are you sure you want to delete this card?"
      );
      if (confirmed) {
        const { data } = await axiosService.delete(
          `/user/card/${payload}/remove`
        );
        return data;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async SET_DEFAULT_CARD({ commit, state }, payload) {
    try {
      const { data } = await axiosService.put(
        `/user/card/${payload}/set_preferred`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
