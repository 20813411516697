import axiosService from "@/services/axiosService";
import useConfirm from "@/hooks/useConfirm";
const confirm = useConfirm();
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async PAY_FOR_PROJECT({ commit, state }, payload) {
    try {
      const { data } = await axiosService.post(
        `/user/project/${payload}/make_payment`
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_ALL_PAYMENTS({ commit, state }, payload) {
    try {
      const { status = "", page = 1, per_page = 10 } = payload ?? {};
      const { data } = await axiosService.get(`/user/invoice/all`, {
        params: {
          status,
          page,
          per_page,
        },
      });
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async GET_PAYMENT_SUMMARY({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/invoice/summary`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async EXPORT_PAYMENT_AS_CSV({ commit, state }, payload) {
    try {
      const { data } = await axiosService.get(`/user/invoice/export`);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
