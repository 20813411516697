import "bootstrap/dist/js/bootstrap.js";
import { numeric, imgLoaded, maxlength } from "@/directives";
import VueDatePicker from "@vuepic/vue-datepicker";
import VueApexCharts from "vue3-apexcharts";
import { createHead } from "unhead";
import { LottieAnimation } from "lottie-web-vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import VOtpInput from "vue3-otp-input";
import Toast from "vue-toastification";
import AppLogo from "@/components/Global/AppLogo";
import Checkbox from "@/components/Global/Checkbox";
import AppLoader from "@/components/Global/AppLoader";
import InlineMessage from "@/components/Global/InlineMessage";
import CountrySelect from "@/components/Global/CountrySelect";
import BackButton from "@/components/Global/BackButton";
import AppState from "@/components/Global/AppState";
import DashboardItemWrapper from "@/components/Global/DashboardItemWrapper";
import DropdownMenu from "@/components/Global/DropdownMenu";
import StatusItem from "@/components/Global/StatusItem";
import FileUpload from "@/components/Global/FileUpload/Index";
import FileUploadItem from "@/components/Global/FileUpload/FileUploadItem";
import DatePicker from "@/components/Global/DatePicker";
import ModalWrapper from "@/components/Global/Modal/ModalWrapper";
import UserAvatar from "@/components/Global/UserAvatar";
import DashboardTab from "@/components/Global/DashboardTab";
import SideModal from "@/components/Global/Modal/SideModal";
import NavCrumbs from "@/components/Global/NavCrumbs";
import AppPagination from "@/components/Global/AppPagination";
import TextTruncate from "@/components/Global/TextTruncate.vue";
const head = createHead();
export default function useImports(app) {
  app.use(head);
  app.use(VueApexCharts);
  app.use(Toast, {
    timeout: 8000,
    pauseOnFocusLoss: false,
    hideProgressBar: false,
    position: "top-center",
    transition: "Vue-Toastification__fade",
  });
  app.directive("numeric", numeric);
  app.directive("img-loaded", imgLoaded);
  app.directive("maxlength", maxlength);
  app.component("VueDatePicker", VueDatePicker);
  app.component("LottieAnimation", LottieAnimation);
  app.component("v-otp-input", VOtpInput);
  app.component("AppLogo", AppLogo);
  app.component("BackButton", BackButton);
  app.component("Checkbox", Checkbox);
  app.component("AppLoader", AppLoader);
  app.component("InlineMessage", InlineMessage);
  app.component("CountrySelect", CountrySelect);
  app.component("AppState", AppState);
  app.component("DashboardItemWrapper", DashboardItemWrapper);
  app.component("DropdownMenu", DropdownMenu);
  app.component("StatusItem", StatusItem);
  app.component("FileUpload", FileUpload);
  app.component("FileUploadItem", FileUploadItem);
  app.component("DatePicker", DatePicker);
  app.component("UserAvatar", UserAvatar);
  app.component("ModalWrapper", ModalWrapper);
  app.component("DashboardTab", DashboardTab);
  app.component("SideModal", SideModal);
  app.component("NavCrumbs", NavCrumbs);
  app.component("AppPagination", AppPagination);
  app.component("Carousel", Carousel);
  app.component("Slide", Slide);
  app.component("Pagination", Pagination);
  app.component("Navigation", Navigation);
  app.component("TextTruncate", TextTruncate);
}
