import AuthLayout from "@/layouts/AuthLayout";
import { authPaths } from "../path.route";
export const AuthRoutes = {
  path: "/",
  redirect: authPaths["login"],
  component: AuthLayout,
  children: [
    {
      path: authPaths["login"],
      name: "Login",
      component: () => import("@/views/auth/Login.vue"),
    },
    {
      path: authPaths["register"],
      name: "Register",
      component: () => import("@/views/auth/Register.vue"),
    },
    {
      path: authPaths["verify_email"],
      name: "VerifyEmail",
      component: () => import("@/views/auth/VerifyEmail.vue"),
    },
    {
      path: authPaths["request_password_reset"],
      name: "RequestReset",
      component: () => import("@/views/auth/RequestReset.vue"),
    },
    {
      path: authPaths["reset_password"],
      name: "ResetPassword",
      component: () => import("@/views/auth/ResetPassword.vue"),
    },
    {
      path: authPaths["verify_two_fa"],
      name: "VerifyTwoFa",
      component: () => import("@/views/auth/VerifyTwoFa.vue"),
    },
  ],
};
